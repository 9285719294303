import * as React from 'react';
import { Image, Row, Col, Container } from 'react-bootstrap';
import { Box, ThemeProvider } from '@mui/system';
import '../styles.css'
import ReactRoundedImage from "react-rounded-image";
import Button from '@mui/material/Button';
import X from '../assets/X.png';
import raydium from '../assets/raydium.png';
import telegram from '../assets/telegram.png';
import uniswap from '../assets/uniswap.png';


export default function OfficialLinks() {
    return(
        <Container fluid>
            <Box sx={{width: "100%"}}>
                <Row className="align-items-center">
                    <Col>
                        <div class="whofont" >
                            OFFICIAL LINKS
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <a target="_blank" href="https://twitter.com/plutotokensol"  rel="noopener noreferrer">
                            <Row className="justify-content-center">
                                <ReactRoundedImage image={X} roundedSize="0"/>
                            </Row>
                        </a>
                    </Col>
                    <Col>
                        <a target="_blank" href="https://t.me/plutotokensol"  rel="noopener noreferrer">
                            <Row className="justify-content-center">
                                <ReactRoundedImage image={telegram} roundedSize="0"/>
                            </Row>
                        </a>
                    </Col>
                    <Col>
                        <a target="_blank" href="https://twitter.com/plutotokensol"  rel="noopener noreferrer">
                            <Row className="justify-content-center">
                                <ReactRoundedImage image={uniswap} roundedSize="0"/>
                            </Row>
                        </a>
                    </Col>
                    <Col>
                    <a target="_blank" href="https://twitter.com/plutotokensol"  rel="noopener noreferrer">
                            <Row className="justify-content-center">
                                <ReactRoundedImage image={raydium} roundedSize="0"/>
                            </Row>
                        </a>
                    </Col>
                </Row>
                <Row>
                    <div class="whofont" >
                            <c>0x000000000000000000000000000000000000dEaD</c>
                    </div>
                </Row>
            </Box>
        </Container>
    )
}