import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="app">
      <Home />
    </div>
    )
}

export default App;
