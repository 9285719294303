import * as React from 'react';
import '../styles.css';
import TitleBubble from '../components/TitleBubble';
import ScrollBar from '../components/ScrollBar';
import { Box, ThemeProvider } from '@mui/system';
import { Image, Row, Col, Container } from 'react-bootstrap';
import HowToBuy from '../components/HowToBuy';
import OfficialLinks from '../components/OfficialLinks';
import WhoIsPluto from '../components/WhoIsPluto';
import MemeSection from '../components/MemeSection';
import Tokenomics from '../components/Tokenomics';
import { Token } from '@mui/icons-material';


function Home() {
    return(

                <Box >
                    <Row>
                        <ScrollBar />
                    </Row>
                    <Row className='mt-5'>
                        <TitleBubble />
                    </Row>
                    <div className='section2'>
                        <Row>
                            <HowToBuy />
                        </Row>
                    </div>
                    <div className='section3'>
                        <Row>
                            <MemeSection />
                        </Row>
                    </div>
                    <div className='section2'>
                        <Row>
                            <WhoIsPluto />
                        </Row>
                        <Row>
                            <Tokenomics />
                        </Row>
                        <Row>
                            <OfficialLinks />
                        </Row>
                    </div>
                </Box>

    )
}

export default Home;