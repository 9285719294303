import * as React from 'react';
import '../styles.css';
import { Image, Row, Col, Container } from 'react-bootstrap';

export default function ScrollBar() {
    return(
        <div class="marquee">
            <p>
                $PLUTO, JUPITER'S BROTHER | $PLUTO, EL HERMANO DE JÚPITER | $PLUTON, LE FRÈRE DE JUPITER | $PLUTO, JUPITERS BRUDER | $PLUTONE, IL FRATELLO DI GIOVE | $PLUTÃO, O IRMÃO DE JÚPITER | $冥王星，木星的兄弟 | $冥王星、木星の兄弟 | $ПЛУТОН, БРАТ ЮПИТЕРА | $بلوتو، شقيق جوبيتر
            </p>
        </div>
    )
}