import React from 'react';
import { Image, Row, Col, Container } from 'react-bootstrap';
import { Box, ThemeProvider } from '@mui/system';
import '../styles.css';  // Import the styles.css file here
import dancingpluto from '../assets/dancing_pluto.gif';

export default function HowToBuy() {
    return (
        <div>
            <Container fluid>
                <Row className="mt-5">

                            {/* Content for the second section goes here */}
                            <Image src={dancingpluto} fluid/>

                </Row>
            </Container>
        </div>
    );
}
