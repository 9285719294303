import * as React from 'react';
import '../styles.css';
import meme1 from '../assets/pluto20.png';
import meme2 from '../assets/pluto21.png';
import meme3 from '../assets/pluto26.png';
import meme4 from '../assets/pluto24.png';
import meme5 from '../assets/pluto25.png';
import meme6 from '../assets/pluto27.png';
import meme7 from '../assets/pluto29.png';
import meme8 from '../assets/pluto28.png';
import meme9 from '../assets/plutosweeney.png';
import meme10 from '../assets/plutoansem.png';
import meme11 from '../assets/plutothug.png';
import meme12 from '../assets/plutonaut.png';
import meme13 from '../assets/plutodrank.png';
import meme14 from '../assets/plutosip.png';
import meme15 from '../assets/plutoscientist.png';
import meme16 from '../assets/pluto-future.png';
import meme17 from '../assets/plutomoon.png';
import { Box, ThemeProvider } from '@mui/system';
import { Image, Row, Col, Container } from 'react-bootstrap';

export default function MemeSection() {
    return(
        <div>
            <Row md={3} >
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme1} fluid />
                    </Box>
                </Col>
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme2} fluid />
                    </Box>
                </Col>
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme3} fluid />
                    </Box>
                    <Box>
                        <Image src={meme4} fluid />
                    </Box>
                </Col>
            </Row>
            
            <Row sm={1} xs={1} md={2}>
                <Col className='mt-5'>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme5} fluid />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme7} fluid />
                    </Box>
                </Col>
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme6} fluid />
                    </Box>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme9} fluid />
                    </Box>
                </Col>
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme10} fluid />
                    </Box>
                </Col>
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme11} fluid />
                    </Box>
                </Col>

            </Row>

            <Row>
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme12} fluid />
                    </Box>
                </Col>
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme13} fluid />
                    </Box>
                </Col>
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme14} fluid />
                    </Box>
                </Col>

            </Row>

            <Row>
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme15} fluid />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme17} fluid />
                    </Box>
                </Col>
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme16} fluid />
                    </Box>
                </Col>

            </Row>

            <Row>
                <Col>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Image src={meme8} fluid />
                    </Box>
                </Col>
            </Row>
        </div>
        
    )
}