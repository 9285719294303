import * as React from 'react';
import { Box, ThemeProvider } from '@mui/system';
import Paper from '@mui/material/Paper';
import { Image, Row, Col, Container } from 'react-bootstrap';
import plutolean from '../assets/pluto-lean.png';
import '../styles.css'

export default function WhoIsPluto () {
    return(
        <Box mb={3} sx={{overflow: 'hidden', width:'2000', borderTop: 1 }} >
                    <Paper style={{
                        backgroundColor: "transparent",
                        
                    }}>
                        <Row>
                            <Col  md={12} lg={6}>
                            <Box mt={3}>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <img style={{padding:"5px", background:"transparent", borderRadius: "10px", maxWidth: "100vw"}} src={plutolean} />
                                    </Box>
                                </Box>
                            </Col>
                            <Col md={12} lg={6}>
                                <Box>
                                    <Box mt={5}>

                                            <div class="whofont">WHO IS PLUTO?</div>

                                    </Box>
                                    <Box mt={4} mr={3} ml={3}>

                                             <div class="whofont">
                                                 <p>
                                                     <Box>Pluto is Jupiter's cooler younger brother.</Box>
                                                     <Box>Pluto loves drinking lean, and sometimes that pisses off Jupiter.</Box>
                                                     <Box>Pluto was actually the result of Jupiter's mom having an affair with Future Hendrix, which is probably where he got his liking for lean from.</Box>
                                                 </p>
                                             </div>
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </Paper>
                </Box>
    )
}