import * as React from 'react';
import { Box, ThemeProvider } from '@mui/system';
import plutologo from '../assets/plutologo.png';
import { Image, Row, Col, Container } from 'react-bootstrap';
import '../styles.css'
import X from '../assets/X.png';
import raydium from '../assets/raydium.png';
import telegram from '../assets/telegram.png';
import uniswap from '../assets/uniswap.png';
import PlaySound from './PlaySound';
import ReactRoundedImage from "react-rounded-image";

export default function TitleBubble() {


    return(

    <ThemeProvider
      theme={{
        palette: {
          primary: {
            main: '#000000',
          },
        },
      }}
    >
    
    <Container fluid>

                <Row lg={2} sm={2} xs={1}>
                    <Col >
                        <Box display="flex" alignItems="center" justifyContent="center" mt={'50px'}>
                            <Row>
                              <div class="titlefont">
                                  PLUTO
                                  <p style={{ marginLeft: '0px', marginTop: '10px' }}>
                                      JUPITER'S BROTHER
                                  </p>
                              </div>
                            </Row>
                        </Box>
                    </Col>
                    <Col >
                        <Box display="flex" alignItems="center" justifyContent="center"
                            sx={{
                              maxWidth: 400,
                              width: "100%",
                              height: "100%",
                              borderRadius: 50,
                              bgcolor: 'rgba(0, 0, 0, 0.66)',
                            }}
                          >
                            <Image src={plutologo} fluid />
                        </Box>
                    </Col>
                </Row>

                <Row className="custom-row-style justify-content-start mt-5 mb-5">
                  {/* On desktop, each column takes 6/12 of the screen width (50%) */}
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Col xs={3} md={2} lg={1}>
                      <a target="_blank" href="https://twitter.com/plutotokensol"  rel="noopener noreferrer">
                        <ReactRoundedImage image={X} imageHeight="75" imageWidth="75" roundedSize="0"/>
                      </a>
                    </Col>
                    <Col xs={3} md={2} lg={1}>
                      <a target="_blank" href="https://t.me/plutotokensol"  rel="noopener noreferrer">
                        <ReactRoundedImage image={telegram} imageHeight="75" imageWidth="75" roundedSize="0"/>
                      </a>
                    </Col>
                    <Col xs={3} md={2} lg={1}>
                      <a target="_blank" href="https://twitter.com/plutotokensol"  rel="noopener noreferrer">
                        <ReactRoundedImage image={uniswap} imageHeight="75" imageWidth="75" roundedSize="0"/>
                      </a>
                    </Col>
                    <Col xs={3} md={2} lg={1}>
                    <a target="_blank" href="https://twitter.com/plutotokensol"  rel="noopener noreferrer">
                      <ReactRoundedImage image={raydium} imageHeight="75" imageWidth="75" roundedSize="0"/>
                    </a>
                    </Col>
                  </Box>
                </Row>



    </Container>

    </ThemeProvider>
    )
}