import * as React from 'react';
import { Box, ThemeProvider } from '@mui/system';
import Paper from '@mui/material/Paper';
import { Image, Row, Col, Container } from 'react-bootstrap';
import tokenomics from '../assets/tokenomics.png';
import '../styles.css'

export default function Tokenomics() {
    return(
        <Box mb={3} sx={{overflow: 'hidden', width:'2000', borderTop: 1 }} >
                    <Paper style={{
                        backgroundColor: "transparent",
                        
                    }}>
                        <Row>
                            <Col md={12} lg={6}>
                                <Box>
                                    <Box mt={5}>

                                            <div class="whofont"><h>PLUTOKENOMICS</h></div>

                                    </Box>
                                    <Box mt={4} mr={3} ml={3}>

                                             <div class="whofont">
                                                 <p>
                                                    
                                                     <Box>Name : PLUTO JUP'S BROTHER</Box>
                                                     <Box>Ticker : $PLUTO</Box>
                                                     <Box>Supply : 100.000.000.000</Box>
                                                     <Box>0% Tax</Box>
                                                     <Box>Mint  & Freeze Revoked </Box>
                                                     <Box>Liquidity Burned Forever</Box>
                                                     <Box>CA: 0x000000000000000000000000000000000000dEaD</Box>
                                                 </p>
                                             </div>
                                    </Box>
                                </Box>
                            </Col>
                            <Col  md={12} lg={6}>
                            <Box mt={3}>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <img style={{padding:"5px", background:"transparent", borderRadius: "10px", maxWidth: "100vw"}} src={tokenomics} />
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </Paper>
                </Box>
    )
}